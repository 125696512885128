



















































import Vue from "vue";

import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import SkiLink from "@/lib/components/SkiLink.vue";
import SkiFilter from "@/lib/components/SkiFilter.vue";

import SkiModal from "@/lib/components/SkiModal.vue";
import {alertMessage, qsParams, isEmpty, generateId } from "@/lib/utilities";

interface Data {
  id: string;
  item: {} | null;
  backUrl: string | null;
  statusUpdates: [] | null;
  categories: [] | null;
  filters: { value: string }[];
  filterIndex: string;
}

export default Vue.extend({
  components: { ArrowLeft, SkiLink, SkiFilter, SkiModal },
  props: {
    backLabel: { type: String, required: true },
    modalLoginKeys: { type: Object, required: true },
    modalErrorKeys: { type: Object, required: true },
    modalAuthErrorKeys: { type: Object, required: true }
  },
  data(): Data {
    return {
      id: "",
      item: null,
      backUrl: null,
      statusUpdates: null,
      categories: null,
      filters: [],
      filterIndex: generateId()
    };
  },
  created() {
    var { id, backUrl } = qsParams();
    if (id.includes(",")) {
      window.history.replaceState(null, "", this.modalCallbackUrl(true));
      id = qsParams().id;
      backUrl = qsParams().backUrl;
    }
    this.id = id;

    if (backUrl) this.backUrl = decodeURIComponent(backUrl);

    this.fetchAPIData();
    this.fetchStatusUpdates();
  },
  methods: {
    onFilter(statusUpdate: any): boolean {
      if (isEmpty(this.filters)) return true;

      return this.filters
        .map(filter => filter.value)
        .includes(statusUpdate.category);
    },
    setFilter(filters: { value: string }[]) {
      this.filters = filters;
    },
    async fetchAPIData() {
      try{
        var agreement = await this.$api.AgreementsRepository.get(this.id);
        this.item = agreement;
        this.$root.$data.item = this.item;
      } catch(err){
        alertMessage(this);
      }
    },
    async fetchStatusUpdateItem(index: number, id: string) {
       try{
          var statusUpdate = await this.$api.StatusUpdateRepository.getStatusUpdate(id);
          if (Array.isArray(this.statusUpdates)) {
              this.$set(
              this.statusUpdates[index],
              "content",
              statusUpdate.description
            );        
       }
       }
        catch(err){
          this.presentErrorModal(err.status);
        }
    },
    async fetchStatusUpdates() {
      try{
        var statusUpdates = await this.$api.StatusUpdateRepository.getStatusUpdateList(this.id);
        const { key, category } = this.$config.agreement.statusUpdate;
        this.statusUpdates = statusUpdates[key];
        this.categories = statusUpdates[category];
      } catch(err){
        alertMessage(this);
      }
    },
    toggleExpand(expanded: string, index: number, id: string) {
      if (expanded) {
        this.fetchStatusUpdateItem(index, id);
      }
    },
    presentErrorModal(statusCode: number) {
      switch (statusCode) {
        case 401:
          this.$bvModal.show(this.modalLoginKeys.modalId);
          break;
        case 403:
          this.$bvModal.show(this.modalAuthErrorKeys.modalId);
          break;
        case 400:
        case 404:
        case 500:
          this.$bvModal.show(this.modalErrorKeys.modalId);
          break;
        default:
          break;
      }
    },
    modalCallbackUrl(replaceComma: boolean = false) {
      let url = window.location.pathname;
      var params = decodeURIComponent(window.location.search);
      url += replaceComma ? params.replace(",", "&") : params.replace("&", ",");
      // url += `#aftaledokumenter`;
      return url;
    }
  }
});
