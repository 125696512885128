












































































































































import Vue from "vue";
import PrintButton from "@/lib/components/PrintButton.vue";
import { formatDate } from "@/lib/dateUtilites";
import { stripHtmlStyle } from "@/lib/utilities";
import Info from "@/lib/assets/mediumicon/info.svg";
import Bulb from "@/lib/assets/icon/bulb.svg";
import DescriptionShortcut from "@/lib/components/DescriptionShortcut.vue";
import { AgreementDescriptionShortcutsDto } from "@/lib/api/APIService";

interface ShortcutType {
  descriptionName?: string;
  showShortcut?: boolean;
}

export default Vue.extend({
  components: { DescriptionShortcut, PrintButton, Info, Bulb },
  props: {
    badgeTitle: { type: String, required: true },
    trafficlightExplainerText: { type: String, default: "" },
    trafficlightExplainerUrl: { type: String, default: "" },
    trafficlightExplainerTarget: { type: String, default: "" },
    sustinabilityShortcutText: { type: String, default: "" },
    greenadviceShortcutText: { type: String, default: "" },
  },
  data() {
    return {
      tenderImplementationPhase:
        this.$config.agreement.descriptions.tenderImplementationPhase,
      application: {},
      popoverShow: false,
      sideMenuId:
        this.$root.$data.item.mainAgreementId === null
          ? "margin-left-menu"
          : "margin-left-menu-sub",
      isLoggedIn: false,
    };
  },
  computed: {
    item() {
      return this.$root.$data.item;
    },
    intoForceDate(): string {
      const { date, altDate } = this.$config.agreement.intoForceDate;
      return this.item[altDate] || formatDate(this.item[date]);
    },
    descriptionShortcuts(): AgreementDescriptionShortcutsDto[] {
      return this.item.descriptionShortcuts;
    },
    sustainabilityShortcut(): ShortcutType {
      var shortcutsFromApi = this.descriptionShortcuts.find(
        (x) => x.descriptionName === "bæredygtighed"
      );

      var shortcut: ShortcutType = {};
      if (shortcutsFromApi !== undefined) {
        shortcut.descriptionName = shortcutsFromApi.descriptionName;
        shortcut.showShortcut = shortcutsFromApi.showShortcut;
      } else {
        shortcut.descriptionName = "bæredygtighed";
        shortcut.showShortcut = false;
      }
      return shortcut;
    },
    greenadviceShortcut(): ShortcutType {
      var shortcutsFromApi = this.descriptionShortcuts.find(
        (x) => x.descriptionName === "gode råd om grønne indkøb"
      );

      var shortcut: ShortcutType = {};
      if (shortcutsFromApi !== undefined) {
        shortcut.descriptionName = shortcutsFromApi.descriptionName;
        shortcut.showShortcut = shortcutsFromApi.showShortcut;
      } else {
        shortcut.descriptionName = "gode råd om grønne indkøb";
        shortcut.showShortcut = false;
      }
      return shortcut;
    },
  },
  async created() {
    const { application } = this.$config.agreement;

    this.application = this.item[application.activeApplication];

    this.isLoggedIn = this.$store.getters.isLoggedIn;
  },
  methods: {
    stripHtmlStyle,
    teaserText() {
      const { descriptions } = this.$config.agreement;
      const result = this.$root.$data.itemDescriptions;
      if (!result) return null;

      const teaser = result.find(
        (item: any) => item[descriptions.title] === descriptions.teaser.key
      );
      if (teaser) return teaser[descriptions.content];

      return null;
    },
    onClose() {
      this.popoverShow = false;
    },
  },
});
