




























import Vue from "vue";

import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";

import { OrgType } from "@/lib/utilities/orgType";

export default Vue.extend({
  components: { SkiDefaultButton },
  props: {
    loggedInCustomerUrl: { type: String, required: true },
    loggedInSupplierUrl: { type: String, required: true },
  },
  // we assume the user is loaded on mounted
  mounted() {
    const profile = this.$store.state.profile;
    if (!profile) return;

    const isCustomer = profile.orgType === OrgType.Kunde;
    const isSupplier = profile.orgType === OrgType.Leverandør || profile.orgType === OrgType.Konsortium;
    if ((isCustomer && isSupplier) || profile.orgType === OrgType.SKI) {
      this.$bvModal.show("bv-modal-select-role");
    } else if (isCustomer) {
      window.location.href = this.loggedInCustomerUrl;
    } else if (isSupplier) {
      window.location.href = this.loggedInSupplierUrl;
    } else {
      console.log("no role", profile.orgType);
      // window.location.href = '/'
    }
  },
});
