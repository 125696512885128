var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed-height flex-modal-display"},[_c('div',{staticClass:"pl-5 pr-5",attrs:{"id":"modal-padding"}},[_c('b-row',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mt-2 text-uppercase text-break"},[_vm._v(_vm._s(_vm.title))])]),_c('b-row',{attrs:{"cols":"1","xl-col":"2"}},[_c('b-col',{staticClass:"teaser-wrapper",attrs:{"col":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('b-col',{staticClass:"search-input-div align-bottom search-wrapper",attrs:{"col":""}},[_c('b-form',{on:{"submit":_vm.searchSubmit}},[_c('b-input-group',[_c('b-form-input',{staticClass:"bg-secondary",attrs:{"type":"search","placeholder":"Søg","aria-label":"Search"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),(_vm.searchQuery)?_c('b-input-group-append',{staticClass:"m-0",attrs:{"id":"bg-clear-icon"}},[_c('ski-round-button',{attrs:{"id":"clear-icon","variant":"dark-opacity"},on:{"click":_vm.clearSearch}},[_c('small-close')],1)],1):_vm._e(),_c('b-input-group-append',{staticClass:"m-0"},[_c('b-button',{staticClass:"py-0 bg-secondary",style:({ zIndex: 'auto' }),attrs:{"type":"submit","variant":"link"}},[_c('search',{staticClass:"svg"})],1)],1)],1)],1)],1)],1),_c('b-row',[(_vm.filterOptions || _vm.sortOptions)?_c('ski-filter',{staticClass:"col-12 py-0 my-ski-statuses-filters",attrs:{"data":_vm.filterData,"table-variant":"white","filter-variant":"light","filter-options":_vm.filterOptions,"sort-options":_vm.sortOption,"filters":_vm.filters,"sort-by":_vm.sortBy,"set-filter":_vm.setFilter,"set-sorting":_vm.setSorting,"filter-index":_vm.filterIndex}}):_vm._e()],1)],1),_vm._t("default",[(_vm.filteredAgreements)?_c('b-row',{staticClass:"agreements-list",attrs:{"cols":"12"}},[_c('b-form-group',[_vm._l((_vm.filteredAgreements),function(agreement){return _vm._t("default",[(
              _vm.isEmpty(agreement.subagreement) ||
              !_vm.isAnySubagreementShown(agreement.subagreement)
            )?_c('b-form-checkbox',{staticClass:"modal-border modal-spacing modal-checkbox",attrs:{"value":agreement.agreementId},model:{value:(_vm.chosen),callback:function ($$v) {_vm.chosen=$$v},expression:"chosen"}},[_c('b',[_vm._v(_vm._s(agreement.displayNameLong))])]):(
              !_vm.isEmpty(agreement.subagreement) &&
              _vm.isAnySubagreementShown(agreement.subagreement)
            )?_c('b-form-checkbox-group',{attrs:{"stacked":""},model:{value:(_vm.chosen),callback:function ($$v) {_vm.chosen=$$v},expression:"chosen"}},[_c('div',{staticClass:"modal-border"},[_c('div',{staticClass:"position-relative cursor-pointer",class:_vm.isAccordionExpanded(agreement.agreementId)
                    ? ''
                    : 'collapsed d-print-none',attrs:{"aria-expanded":_vm.isAccordionExpanded(agreement.agreementId)
                    ? 'true'
                    : 'false'},on:{"click":function($event){return _vm.toggleExpand(agreement.agreementId)}}},[_c('div',{staticClass:"mr-5 modal-spacing"},[_c('span',{staticClass:"accordion-toggle"},[(!_vm.isAccordionExpanded(agreement.agreementId))?_c('transition',{attrs:{"name":"clockwise","mode":"out-in"}},[_c('span',{key:"open"},[_c('closed',{staticClass:"rotated"})],1)]):_c('transition',{attrs:{"name":"counter","mode":"out-in"}},[_c('span',{key:"closed"},[_c('closed')],1)])],1),_vm._t("title",[_c('b',[_vm._v(_vm._s(agreement.displayNameLong))])])],2)]),_c('transition',{attrs:{"name":"fold"}},[(_vm.isAccordionExpanded(agreement.agreementId))?_c('div',{staticClass:"pb-5 text-break",attrs:{"id":"modal-sub-checkbox-container"}},[_vm._t("content",_vm._l((agreement.subagreement.filter(function (sub) { return _vm.shouldSubagreementShow(sub); }
                      )),function(sub){return _c('b-form-checkbox',{key:sub.subAgreementId,staticClass:"modal-sub-checkbox modal-checkbox",attrs:{"value":sub.subAgreementId}},[_vm._v(" "+_vm._s(sub.subAgreementNumber + " " + sub.subAgreementName)+" ")])}))],2):_vm._e()])],1)]):_vm._e()])})],2)],1):_vm._e(),_c('b-row',{attrs:{"cols":"12","align-h":"center"}},[_c('b-button',{staticClass:"my-5 btn-ski py-3 px-4",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_c('span',{staticClass:"mr-large"},[_vm._v(_vm._s(_vm.submitButtonText))]),_c('arrow',{staticClass:"svg"})],1)],1),_c('b-row',{attrs:{"cols":"12","align-h":"center"}},[_c('b-link',{staticClass:"link-position underline-link",on:{"click":_vm.clearChosen}},[_vm._v(_vm._s(_vm.removeAllSubsText))])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }