







































































import Closed from "@/lib/assets/icon/minus.svg";
import Open from "@/lib/assets/icon/open.svg";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import { downloadTableCSV, qsParams, stripHtmlStyle } from "@/lib/utilities";
import Vue from "vue";

export default Vue.extend({
  components: {
    SkiDefaultButton,
    Open,
    Closed
  },
  props: {
    fields: { type: Array, default: null },
  },
  data() {
    return {
      apiGroupKeys: this.$config.agreement.descriptions.customers,
      toggledMaxElements: 10,
      contentArray: [] as any,
      filteredCustomers: [] as any,
      loading: false,
      listToggled: false,
      id: ""
    };
  },
  created(){
    this.id = qsParams().id;
    this.getCustomers();
  },
  computed: {
    customerText: function () {
      return this.$root.$data.item.textForCustomers;
    }
  },
  methods: {
    stripHtmlStyle,
    downloadTableCSV,
    getCustomers(){
      this.filteredCustomers = null;
      this.displaySpinner();
      this.fetchCustomerData();
    },
    async fetchCustomerData() {
     var customerList = await this.$api.AgreementsRepository.getCustomers(this.id);
     this.loading = false;
  
    if (customerList){
          customerList.forEach((application: any) => {
            application.customerList.forEach((customer: any) => {
              this.contentArray.push(customer);
            })
          });
        }
        if(this.contentArray.length > this.toggledMaxElements){
          this.filteredCustomers = this.sliceItems(this.contentArray);
        }
        else {
          this.filteredCustomers = this.contentArray;
        }
    }, 
    columnWidth(field: any) {
      if(field.key === "companyname"){
        return "30%"
      } else if(field.key === "applicationLabel"){
        return "20%"
      } else if (field.key === "connectiontext"){
        return "50%"
      } else{
        return `${(1 / this.fields.length) * 100}%`;
      }
        },
    itemLink(item: any): string {
      const { organizationUrl } = this.$root.$data;
      return `${organizationUrl}?id=${item[this.apiGroupKeys.orgId]}`;
    },
    existText(){
      return (!this.$root.$data.item.textForSuppliers || this.$root.$data.item.textForCustomers !== null)
    },
    filterAllCustomers() {
       this.filteredCustomers = this.contentArray;      
       if (!this.listToggled) {
            this.filteredCustomers = this.sliceItems(this.filteredCustomers);
       }
    },
    sliceItems(computedItems: any[]): any[] {
      return computedItems.slice(0, this.toggledMaxElements);
    },
    toggleList() {
      this.listToggled = !this.listToggled;
      this.filterAllCustomers()
    },
    displaySpinner(){
      setTimeout(() => {
        if(this.filteredCustomers === null){
          this.loading = true;
        }
      }, 1000);
    }
  }
});
