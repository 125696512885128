

















































































import Vue from "vue";
import Arrow from "@/lib/assets/icon/arrow.svg";
import Notification from "@/lib/assets/icon/notification.svg";
import SkiTable from "@/lib/components/SkiTable.vue";
import MySkiRowHeader from "@/lib/partials/my-ski/MySkiRowHeader.vue";
import {
  loadCurrentSubscriptionDate,
  errorHandling,
  alertException,
  getValue,
  isEmpty,
  convertCrmStatusToSkiStatus,
  loadCurrentSubscriptions,
  saveCurrentSubscriptions,
  generateId,
} from "@/lib/utilities";
import MySkiSubscriptionModal from "@/lib/partials/my-ski/modules/MySkiStatusUpdate/MySkiSubscriptionModal.vue";

import dayjs from "dayjs";

interface Data {
  skiAgreementlist: [] | null;
  subscribedAgreementList: string[];
  data: {} | null;
  userInActive: boolean;
  userNotSubscribed: boolean | false;
  tableIndex: string;
}

export default Vue.extend({
  components: {
    SkiTable,
    Arrow,
    MySkiRowHeader,
    MySkiSubscriptionModal,
    Notification,
  },
  props: {
    fields: { type: Array, required: true },
    sortOptions: { type: Array, required: true },
    filterOptions: { type: Array, required: true },
    statusMap: { type: Array, required: true },
    overviewTitle: { type: String, required: true },
    overviewText: { type: String, default: "" },
    overviewOpenModalText: { type: String, required: true },
    modalTitle: { type: String, required: true },
    modalText: { type: String, default: "" },
    modalTitleChanged: { type: String, required: true },
    modalTextChanged: { type: String, default: "" },
    modalSubmitButtonText: { type: String, required: true },
    modalRemoveAllSubsText: { type: String, required: true },
    skiStatuses: { type: Array, required: true },
  },
  data(): Data {
    return {
      skiAgreementlist: null,
      subscribedAgreementList: [],
      data: null,
      userNotSubscribed: false,
      userInActive: false,
      tableIndex: generateId(),
    };
  },
  created() {
    this.fetchAPIData();
    this.$root.$on("bv::modal::hidden", this.fetchAPIData);
  },
  watch: {
    "$store.state.profile"(newVal) {
      this.fetchAPIData();
    },
  },
  methods: {
    async fetchAPIData() {
      if (this.userInActive) {
        return null;
      }
      const user = this.$store.state.profile;
      if (user == null) return null;
      var cachedDataDate = loadCurrentSubscriptionDate();
      var cachedData = loadCurrentSubscriptions();
      if (cachedData != null && dayjs().isBefore(dayjs(cachedDataDate))) {
        this.convertApiData(cachedData);
        return null;
      }
      try {
        var statusUpdates =
          await this.$api.StatusUpdateRepository.getStatusUpdatesForTheUser(
            user.email
          );
        if (statusUpdates !== undefined || statusUpdates !== null) {
          this.convertApiData(statusUpdates);
          saveCurrentSubscriptions(statusUpdates);
        } else {
          this.userNotSubscribed = true;
          this.data = {
            skiSourcingprograms: [],
            skiGroups: [],
            skiStatuses: this.skiStatuses,
          };
        }
      } catch (err) {
        if (err.status === 403) {
          this.userInActive = true;
          alertException(
            this,
            "Vi kan se, at din e-mail er deaktiveret i vores system. For at tilmelde dig statusopdateringer skal du derfor tage fat i SKI’s kundeservice på kundeservice@ski.dk, så vi kan hjælpe dig med at aktivere din e-mail igen.",
            "Brugerprofil inaktiv"
          );
        } else {
          errorHandling(
            this,
            err,
            "/v1/user/:id/status-update-subscription",
            user.email
          );
        }
      }
    },
    isEmpty,
    openModalWindow(event: Event) {
      event.preventDefault();
      this.$bvModal.show("mySkiModal");
    },
    convertApiData(data: any) {
      var preItems = data.skiAgreementlist;
      this.userNotSubscribed =
        data.skiAgreementlist === null || data.skiAgreementlist.length === 0;
      for (var id in preItems) {
        var item = getValue(id, preItems);
        convertCrmStatusToSkiStatus(item);
        item.icon = "svg";
        preItems[id] = item;
      }
      this.skiAgreementlist = preItems;
      if (preItems !== null) {
        this.subscribedAgreementList = [];
        for (var i = 0; i < preItems.length; i++) {
          this.subscribedAgreementList.push(preItems[i]["agreementId"]);
        }
      }
      this.data = {
        skiSourcingprograms: data.skiSourcingprograms,
        skiGroups: data.skiGroups,
        skiStatuses: this.skiStatuses,
      };
    },
  },
});
