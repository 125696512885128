























import Vue, { PropType } from "vue";
import Accordion from "@/lib/components/Accordion.vue";
import { replaceAll, replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem, stripHtmlStyle } from "@/lib/utilities";
import { resetHashtagValue } from "@/lib/urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagaccordionControlId: string;
  yearlyReportContent: string;
}

export default Vue.extend({
  components: { Accordion },
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    accordionArray: { type: Array as PropType<any>, required: true },
    titleField: { type: String, required: true },
    contentField: { type: String, required: true }
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagaccordionControlId: "#" + this.title.toLowerCase(),
      yearlyReportContent: ""
    };
  },
  created() {
    this.accordionArray.forEach((element: any) => {
      if(element.title.toLowerCase().includes("årsrapporter")) {
        this.yearlyReportContent = element.content;
        this.yearlyReportContent = replaceAll(element.content, "<a ", "<a class=\"monsido_download\"");
      }
    });
  },
  async mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagaccordionControlId) {
      resetHashtagValue();
      await this.scrollToAnchorItem();
    }
  },
  methods: {
    async scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var accordionControlId = this.hashtagaccordionControlId.split("#")[1];
      scrollToAnchorItem(accordionControlId, 0, 24);
    },
    stripHtmlStyle
  },
});
