


































































































import Vue from "vue";

import Arrow from "@/lib/assets/icon/arrow.svg";
import Notification from "@/lib/assets/icon/notification.svg";
import CheckMark from "@/lib/assets/icon/checkmark.svg";
import StatusCard from "@/lib/components/StatusCard.vue";
import SkiModal from "@/lib/components/SkiModal.vue";
import {qsParams, isEmpty, getValue, loadCurrentSubscriptions, loadCurrentSubscriptionDate, saveCurrentSubscriptions, alertMessage, replaceHexWithNordicLettersAndWhitespace, scrollToItemTop } from "@/lib/utilities";
import SkiRoundButton from "@/lib/components/SkiRoundButton.vue";
import { SkiAgreementRequest } from "@/lib/api/APIService";


import Close from "@/lib/assets/icon/close.svg";
import dayjs from "dayjs";


interface Data {
  id: string;
  callbackUrl: string;
  statusUpdates: {}[] | null;
  isSubscribed: boolean | null;
  inputEmail: string | "";
  nonValidEmail: boolean | false;
  item: any | null;
  userData: any | null;
  subscribed: boolean | false;
  knownSubscriber: boolean | false;
  usersCurrentAgreements: string[];
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagStatusId: string;
}

export default Vue.extend({
  components: {
    Notification,
    CheckMark,
    Arrow,
    StatusCard,
    SkiModal,
    SkiRoundButton,
    Close,
  },
  props: {
    statusTitle: { type: String, required: true },
    statusDescription: { type: String, required: true },
    statusUpdatesUrl: { type: String, required: true },
    modalLoginKeys: { type: Object, required: true }
  },
  data(): Data {
    return {
      id: "",
      callbackUrl: "",
      statusUpdates: null,
      isSubscribed: null,
      inputEmail: "",
      nonValidEmail: false,
      item: this.$root.$data.item,
      userData: null,
      subscribed : false,
      knownSubscriber : false,
      usersCurrentAgreements : [],
      hashtagFromWindow: window.location.hash,
      hashtagItem: "",
      hashtagStatusId: "#status"
    };
  },
  async created() {
    this.id = qsParams().id;
    const userData = this.$store.state.profile
    if (userData !== null && userData.email !== undefined) {
      this.inputEmail = userData.email;
      this.userData = userData;
    }
    this.fetchStatusUpdates();
    this.checkIfSubscribed();
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagStatusId) {
      this.scrollToAnchorItem();
    }
  },
  methods: {
    isEmpty,
    async fetchStatusUpdates() {
      try{
        var statusUpdates = await this.$api.AgreementsRepository.getStatusUpdatesAgreement(this.id);
        const { key } = this.$config.agreement.statusUpdate;
        this.statusUpdates = ((statusUpdates || {})[key] || []).slice(0, 2);
      } catch(err){
        alertMessage(this);
      }
    },
    getStatusUpdatesUrl(statusId: string) {
      let url = this.statusUpdatesUrl;
      url += `?id=${this.id}`;
      url += `&backUrl=${encodeURIComponent(window.location.pathname)}`;
      if (statusId) url += `#accordion-${statusId}`;
      return url;
    },
    async checkIfSubscribed(){
      this.isSubscribed = false;
      if(this.userData == null)
        return null;
      var subs = loadCurrentSubscriptions();
      var subsDate = loadCurrentSubscriptionDate();
      if(!!subs && dayjs().isBefore(dayjs(subsDate))) {
        this.isSubscribed = !!subs.skiAgreementlist.find((item: any) => item.agreementId === this.id);
      }
      else {
        try{
          var statusUpdates = await this.$api.StatusUpdateRepository.getStatusUpdatesForTheUser(this.userData.email);
            var data = statusUpdates
            var preItems = getValue("skiAgreementlist", data) as [any];
            if(preItems != null){
            this.isSubscribed = !!preItems.find(item  => item.agreementId === this.id);
            data.expires = dayjs().add(10, "minute");
            saveCurrentSubscriptions(data);
            }
        } catch(err){
          alertMessage(this);
        }
      }
    },
    presentSubscriptionModal () {
      this.$bvModal.show("subscription-modal");
    },
    async sendToApi () {
      if (!this.validateEmail(this.inputEmail)) {
        this.nonValidEmail = true;
        return;
      } else {
        this.nonValidEmail = false;
      }
      this.sendPostRequest("/v1/user/:id/status-update-subscription");
      if (this.userData == null) {
        this.knownSubscriber = false;
      } else {
        this.knownSubscriber = true;
        this.addThisToSubscribedList();
      }
      this.isSubscribed = true ;
    },
    validateEmail(email: string | null)
    {
        if (email === null) {
          return false;
        }
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    async sendPostRequest(url : string | "") {
      var postRequestObject = { "aftid": this.id } as SkiAgreementRequest;
      this.subscribed = true;
      
        try{
          await this.$api.StatusUpdateRepository.postStatus(this.inputEmail, postRequestObject);
        } catch(err) {
          alertMessage(this);
          this.subscribed = false;
        }
    },
    addThisToSubscribedList() {
      var cachedData = loadCurrentSubscriptions();
      // Cast cachedData as boolean
      // eslint-disable-next-line no-extra-boolean-cast
      if(!!cachedData) {
        cachedData.skiAgreementlist.push(this.item);
        saveCurrentSubscriptions(cachedData);
      }
    },
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var statusId = this.hashtagStatusId.split("#")[1];
      var element = document.getElementById(statusId);
      if (element) {
        scrollToItemTop(element);
      }
    }
  }
});
