



































import Vue from "vue";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import Close from "@/lib/assets/icon/close.svg";
import dompurify from "dompurify";

export default Vue.extend({
  components: {
    SkiDefaultButton,
    Close
  },
  props: {
    active: { type: Boolean, default: true },
    buttonName: { type: String, default: null },
    buttonUrl: { type: String, default: null },
    buttonTarget: { type: String, default: null },
    text: { type: String, required: true },
    topPlacement: {type: Boolean, default: false},
    variant: { type: String, default: "light" },
    buttonVariant: { type: String, default: "primary" }
  },
  data() {
    return {
      closedByUser: false,
      greenTextSet: this.variant === "lightGrey"
    };
  },
  computed: {
    dataHTML() {
      // Strip all attributes from html
      const htmlString = dompurify.sanitize(this.text, {
        ALLOWED_TAGS: ["a", "strong"],
        ALLOWED_ATTR: ["href", "target"]
      });

      const html = new DOMParser().parseFromString(htmlString, "text/html");

      return html.documentElement.outerHTML;
    },
    btnColor(): string {
      if (this.variant === this.buttonVariant) {
        return "info-opacity";
      }
      return `${this.buttonVariant}`;
    }
  },
  created() {
    if (sessionStorage.getItem("userClosedNotification")) {
      this.closedByUser = true;
    }
  },
  methods: {
    close() {
      sessionStorage.setItem("userClosedNotification", "True");
      this.closedByUser = true;
    }
  }
});
