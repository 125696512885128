



























import DocumentCard from "@/lib/components/DocumentCard.vue";
import SkiModal from "@/lib/components/SkiModal.vue";
import { configurationProvider } from "@/lib/ConfigurationProvider";

import { compareValues, getFileTypeFromExtension } from "@/lib/utilities";
import saveAs from "file-saver";
import { DocumentAuthorizationUnauthorizedReason, IDocumentAuthorization } from "@/lib/api/APIService";
import Vue from "vue";

export default Vue.extend({
  components: { SkiModal, DocumentCard },
  props: {
    contentArray: { type: Array, required: true },
    modalLoginKeys: { type: Object, required: true },
    modalErrorKeys: { type: Object, required: true },
    modalAuthErrorKeys: { type: Object, required: true },
    title: { type: String, required: true },
  },
  data() {
    return {
      apiGroupKeys: this.$config.agreement.descriptions.agreementDocuments,
      documentId: "",
    };
  },
  computed: {
    documentGroupArray() {
      const documents: any[] = this.contentArray;
      const keys = this.apiGroupKeys;

      const uniqueDocGroups = [
        ...new Set(documents.map((item) => item[keys.docType])),
      ];

      // Creating new document group objects only for those groups that have corresponding documents
      const docGroupObjects = uniqueDocGroups.reduce((accumulator, group) => {
        const docsUnsorted = documents.filter(
          (doc) =>
            doc[keys.docType] === group &&
            (doc.accordionName === this.title ||
              (!doc.accordionName && this.title === "Aftaledokumenter"))
        );

        if (docsUnsorted.length > 0) {
          docsUnsorted.forEach(async (doc) => {
            doc.fileName =
              doc.fileName === null ? keys.nameIfNull : doc.fileName;
            doc._fileType = getFileTypeFromExtension(doc[keys.docFileName]);

            const docProps = this.getDocProps(
              doc[keys.docId],
              doc.userAccess,
              this.$store.getters.isLoggedIn
            );

            doc._url = docProps.url;
            doc._label = docProps.label;
            doc._icon = docProps.icon;
          });

          const obj = {
            docGroupName: group || "",
            docGroupSortKey: documents.find(
              (doc) => doc[keys.docType] === group
            )[keys.docGroupSortKey],
            docs: docsUnsorted.sort(compareValues(keys.docSortKey)),
          };

          accumulator.push(obj);
        }

        return accumulator;
      }, []);

      return docGroupObjects.sort(compareValues(keys.docGroupSortKeyReference));
    },
  },
  methods: {
    async fetchDocument(document: any) {
      if (!event) return;
      event.preventDefault();

      const keys = this.apiGroupKeys;

      this.documentId = document[keys.docId];
      if (!document[keys.loginRequired] || this.$store.getters.isLoggedIn) {
        try {
          const response = await this.$api.DocumentRepository.getDocument(
            this.documentId
          );
          saveAs(response.data, `${document.fileName}`);
          this.presentModal(response.status);
        } catch (err) {
          const error = err as any;
          this.presentModal(error.statusCode);
        }
      } else {
        this.$store.dispatch("signIn", this.modalCallbackUrl());
      }
    },
    presentModal(statusCode: number) {
      switch (statusCode) {
        case 200:
          break;
        case 401:
        case 403:
          this.$bvModal.show(this.modalAuthErrorKeys.modalId);
          break;
        case 999:
          this.$bvModal.show(this.modalLoginKeys.modalId);
          break;
        default:
          this.$bvModal.show(this.modalErrorKeys.modalId);
      }
    },
    getDocProps(
      docId: string,
      userAccess: IDocumentAuthorization,
      isLoggedIn: boolean
    ) {
      // TODO: Revisit this, when Stamdata API is updated to SSO
      const docObj = { url: "", label: "", icon: "" };

      if (userAccess.authorized) {
        let docUrl = configurationProvider.api.documentBaseUrl;
        docUrl += "/" + this.apiGroupKeys.endpoint_id.replace(":id", docId);

        docObj.url = docUrl;
        docObj.label = "Download";
        docObj.icon = "Download";
      } else if (isLoggedIn && !userAccess.authorized) {
        docObj.url = "//";
        docObj.label = this.getErrorMessage(userAccess);
        docObj.icon = "Lock";
      } else if (!isLoggedIn) {
        docObj.url = "//";
        docObj.label = "Log ind for at downloade";
        docObj.icon = "Lock";
      }

      return docObj;
    },
    getErrorMessage(userAccess: IDocumentAuthorization): string {
      switch (userAccess.unauthorizedReason) {
        case DocumentAuthorizationUnauthorizedReason._0:
          return "Mangler adgang. Kontakt din brugeradministrator";
        case DocumentAuthorizationUnauthorizedReason._1:
          return "Kræver kundelogin";
        case DocumentAuthorizationUnauthorizedReason._2:
          return "Kræver kunde- eller leverandørlogin";
        case DocumentAuthorizationUnauthorizedReason._3:
          return "Der opstod en fejl. Kontakt SKI Kundeservice, hvis problemet fortsætter";
          default:
            return "Der opstod en fejl. Kontakt SKI Kundeservice, hvis problemet fortsætter";
      }
    },
    modalCallbackUrl() {
      let url = window.location.pathname;
      if (!url.includes(`&documentId=${this.documentId}`)) {
        url += window.location.search + `&documentId=${this.documentId}`;
      }
      return url;
    },
  },
});
