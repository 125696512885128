


























import Vue from "vue";
import Download from "@/lib/assets/icon/download.svg";
import Lock from "@/lib/assets/icon/lock.svg";
import { AgreementDocumentsListViewDto } from "../api/APIService";

export default Vue.extend({
  components: { Download, Lock },
  props: {
    doc: { type: AgreementDocumentsListViewDto || null, default: null },
    fetchDocument: { type: Function, default: null },
    accordionName: { type: String, default: null },
    fileType: { type: String, default: null },
    fileName: { type: String, default: null },
    label: { type: String, default: null },
    monsidoComponent: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    documentAuthorized(doc: AgreementDocumentsListViewDto | null): boolean | undefined {
      return doc?.userAccess?.authorized;
    },
    async monsidoClick(
      monsidoComponent: boolean,
      fileName: string,
      doc: AgreementDocumentsListViewDto | null = null
    ) {
      if (doc === null || doc === undefined || !doc.userAccess?.authorized) {
        return;
      }
      if (monsidoComponent && doc.userAccess?.authorized) {
        const monsidoDocumentComponent = document.getElementById(
          `monsidoDocumentId-${doc ? doc.documentId : fileName}`
        );
        if (monsidoDocumentComponent) {
          monsidoDocumentComponent.click();
        }
      } else {
        await this.docClick(doc);
      }
    },
    async docClick(document: AgreementDocumentsListViewDto | null = null) {
      if (!document) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      } else {
        this.loading = true;
        await this.fetchDocument(document);
        this.loading = false;
      }
    }
  }
});
