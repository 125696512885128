










































import Vue from "vue";
import ContactCard from "@/lib/components/ContactCard.vue";
import { isEmpty, replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import {getNow} from '@/lib/dateUtilites';
import { resetHashtagValue } from "@/lib/urlUtilities";

interface Data {
  contacts: any;
  config: {};
  ids: string;
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagContactId: string;
  delay: number;
}

export default Vue.extend({
  components: { ContactCard },
  provide() {
    return {
      lazyLoadImages: this.lazyloadImages
    };
  },
  props: {
    contactArray: { type: Array, default: null },
    contactTitle: { type: String, required: true },
    contactDescription: { type: String, required: true },
    lazyloadImages: { type: Boolean, default: true }
  },
  data(): Data {
    return {
      contacts: null,
      config: {},
      ids: "",
      hashtagFromWindow: window.location.hash,
      hashtagItem: "",
      hashtagContactId: "#" + this.contactTitle.toLowerCase(),
      delay: 0
    };
  },
  async created() {
    this.delay = 200;
    // Checking whether data comes from API or CMS
    if (this.contactArray) {
      if (!isEmpty(this.contactArray)) {
        const { employee } = this.$config;

        // Adapting query based on number of employees to match API format
        this.ids =
          this.contactArray.length === 1
            ? `${this.contactArray[0]}`
            : this.contactArray.join(",");

        this.config = employee;
        await this.fetchAPIData();

        this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow);
        if (this.hashtagItem === this.hashtagContactId) {
          resetHashtagValue();
          await this.scrollToAnchorItem();
        }       
      }
    } else {
      const { contact } = await this.$config.agreement;
      const allContacts = await this.$root.$data.item[contact.key];

      this.contacts = allContacts.filter((x: any) => this.datestringIsBeforeNow(x.skiStartdato) && this.datestringIsAfterNow(x.skiSlutdato))
      
      this.config = await contact;
      
      this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow);
      if (this.hashtagItem === this.hashtagContactId) {
        resetHashtagValue();
        await this.scrollToAnchorItem(200);
      } 
    }
  },
  methods: {
    isEmpty,
    async fetchAPIData() {
      if(this.ids ||this.ids != null){
        var employees = await this.$api.EmployeeRepository.getEmployee(this.ids);
        if (employees) {
          this.contacts = employees.skiEmployeesList;
        }
      }
    },
    async scrollToAnchorItem(delay = 0) {
      // Remove hashtag from prop to get the id of the element
      var contactId = this.hashtagContactId.split("#")[1];
      scrollToAnchorItem(contactId, delay);
    },
    datestringIsBeforeNow(datestring: string | undefined): boolean{
      if(!datestring){
        return true;
      }
      const date = new Date(Date.parse(datestring));
      return date <= getNow();
    },
    datestringIsAfterNow(datestring: string | undefined): boolean{
      if(!datestring){
        return true;
      }
      const date = new Date(Date.parse(datestring));
      return date >= getNow();
    }
  }
});
