









































import Vue from "vue";
import { qsParams } from "@/lib/utilities";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import Catalogue from "@/lib/assets/icon/catalogue.svg";
import dompurify from "dompurify";

export default Vue.extend({
  components: { SkiDefaultButton, Catalogue },
  props: {
    viewCatalogueButtonLabel: { type: String, required: true },
    viewCatalogueButtonUrl: { type: String, required: true },
    viewCatalogueButtonTarget: { type: String, default: null },
    orderCatalogueButtonLabel: { type: String, required: true },
    orderCatalogueButtonUrl: { type: String, required: true },
    orderCatalogueButtonTarget: { type: String, default: null },
    greenCatalogueButtonLabel: { type: String, required: true },
    greenCatalogueButtonUrl: { type: String, required: true },
    greenCatalogueButtonTarget: { type: String, default: null },
    disButtonLabel: { type: String, default: "" }, // Default text for the button for an agreements Dynamiske Indkøbssystem (DIS) set in Ubmraco BackOffice
  },
  data() {
    return {
      showCatalogueButtons: false,
      showOrderCatalogueButton: false,
      showGreenCatalogueButton: false,
      showDisButton: false, // Whether to show a button linking to an agreement's Dynamiske Indkøbssystem (DIS)
      disButtonUrl: "", // The URL address to an agreement's Dynamiske Indkøbssystem (DIS)
      disButtonText: "", // Unique text for the button for  an agreements Dynamiske Indkøbssystem (DIS). This overwrites the default text set in Ubmraco BackOffice
      viewCatalogueButtonUrlEncoded: "",
      orderCatalogueButtonUrlEncoded: "",
      greenCatalogueButtonUrlEncoded: "",
      isLoggedIn: false,
    };
  },
  async created() {
    const { item } = this.$root.$data;

    this.createLinksToEPortal()
    if (item.orderCatalogue) {
      this.showOrderCatalogueButton = true;
    }

    if (item.catalogue) {
      this.showCatalogueButtons = true;
    }

    // Only show green catalogue button if Order Catalogue Button is shown and green catalogue is set to true in CRM
    if (this.showOrderCatalogueButton && item.greenCatalogue) {
      this.showGreenCatalogueButton = true;
    }

    if (item.showDisButton) {
      this.showDisButton = true;
      this.disButtonUrl = dompurify.sanitize(item.disButtonUrl);
      this.disButtonText = item.disButtonText
        ? item.disButtonText
        : this.disButtonLabel;
    }
  },
  methods: {
    createLinksToEPortal() {
      const { id } = qsParams();
      const { item } = this.$root.$data;
      const { fullName, suppliers } = this.$config.agreement;

      // Hardcoded ski catalogue filter that takes agreement agreement title as parameter
      const filter =
        "agreements=" +
        encodeURIComponent(`{"id":"${id}","name":"${item[fullName]}"}`);

      const greenFilter = filter + "&isGreen=true";

      this.viewCatalogueButtonUrlEncoded = this.viewCatalogueButtonUrl;
      this.viewCatalogueButtonUrlEncoded += "&" + filter;
      this.viewCatalogueButtonUrlEncoded = dompurify.sanitize(
        this.viewCatalogueButtonUrlEncoded
      );

      this.orderCatalogueButtonUrlEncoded = dompurify.sanitize(
        `${this.orderCatalogueButtonUrl}&agreementId=${id}&supplierId=${
          item[suppliers.key][0][suppliers.orgId]
        }`
      );

      this.greenCatalogueButtonUrlEncoded = this.greenCatalogueButtonUrl;
      this.greenCatalogueButtonUrlEncoded += "&" + greenFilter;
      this.greenCatalogueButtonUrlEncoded = dompurify.sanitize(
        this.greenCatalogueButtonUrlEncoded
      );
    },
  },
});
