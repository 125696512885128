

































































import { stripHtmlStyle, getValue } from "@/lib/utilities";
import Vue from "vue";
import Open from "@/lib/assets/icon/open.svg";
import Closed from "@/lib/assets/icon/minus.svg";

export default Vue.extend({
  components: {
    Open,
    Closed,
  },
  props: {
    contentArray: { type: Array, required: true },
    fields: { type: Array, default: null },
  },
  data() {
    return {
      organizationUrl: this.$root.$data.organizationUrl,
      mailtoUrl: "",
      agreementName: this.$root.$data.item.displayNameLong,
      listToggled: this.contentArray.length > 10 ? false : null,
      toggledMaxElements: 10,
      filteredSuppliers: this.contentArray
    };
  }, 
  created(){
    this.setMailtoUrl();
    if(this.contentArray.length > this.toggledMaxElements){
      this.filteredSuppliers = this.sliceItems(this.filteredSuppliers);
    }
  },
  computed: {
    Supplierstext: function () {
      return this.$root.$data.item.textForSuppliers;
    }
  },
  methods: {   
    stripHtmlStyle,
    comlumWidth() {
      return `${(1 / this.fields.length) * 100}%`;
        },
    filterAllSuppliers() {
      this.filteredSuppliers = this.contentArray;      
      if (!this.listToggled) {
          this.filteredSuppliers = this.sliceItems(this.filteredSuppliers);
      }
    },
    sliceItems(computedItems: any[]): any[] {
      return computedItems.slice(0, this.toggledMaxElements);
    },
    existText(){
      return (this.$root.$data.item.textForSuppliers || this.$root.$data.item.textForSuppliers !== null)
    },
    setMailtoUrl(){
      var link = "";
      for (const [i, value]  of this.contentArray.entries()){
        const supplier = value as any;
        const supplyRoles = supplier.supplyRoles;
        var supplyRole = {} as any;
        for(supplyRole in supplyRoles){
          var val = getValue(supplyRole, supplyRoles);
          if(getValue("role", val) === "Udbudspostkasse")
          {
            var email = getValue("email", val);
            if((email)){
              if(link.length > 0){
                link += ";";
              }
            link += email;
            }
          }
        }
      }
      if((link)){
        this.mailtoUrl = encodeURI("mailto:"+link+"?cc=kundeservice@ski.dk&subject=Vedr. "+this.agreementName);
      }
    },
    toggleList() {
      this.listToggled = !this.listToggled;
      this.filterAllSuppliers()
    },
    checkForDynamiskIndkøbssystem() {
      var tenderProcess = this.$root.$data.item.tenderProcess;
      if(tenderProcess == "Dynamisk indkøbssystem")
      {
       return true;
      }
      return false;
    },
  }
});
