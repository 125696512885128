




















import Vue from "vue";
import { stripHtmlStyle } from "@/lib/utilities";
import Status from "@/lib/assets/icon/status.svg";

export default Vue.extend({
    name: "InfoBox",
    components: { Status },
    props: {
        infoText: { type: String, default: "" },
        catalogBtnShown: {type: Boolean, default: false },
    },
  methods: {
    stripHtmlStyle,
  }
});
