
















import Vue from "vue";
import dompurify from "dompurify";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import { resetHashtagValue } from "../urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagFactboxId: string;
}

export default Vue.extend({
  props: {
    title: { type: String, default: null },
    table: { type: String, required: true }
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagFactboxId: "#" + this.title.toLowerCase()
    };
  },
  computed: {
    dataHTML() {
      // Strip all attributes from html
      const htmlString = dompurify.sanitize(this.table, {
        ALLOWED_ATTR: ["href"]
      });
      const html = new DOMParser().parseFromString(htmlString, "text/html");
      this.appendClass(html, "table", [
        "table",
        "b-table",
        "rte-table",
        "table-borderless",
        "table-striped"
      ]);

      return html.documentElement.outerHTML;
    }
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagFactboxId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    }    
  },
  methods: {
    appendClass(root: Document, selector: string, classNames: string[]) {
      root.querySelectorAll(selector).forEach(element => {
        element.classList.add(...classNames);
      });
    },
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var factboxId = this.hashtagFactboxId.split("#")[1];
      scrollToAnchorItem(factboxId);
    }
  }
});
