















































import Vue from "vue";
import EventCardTile from "@/lib/components/EventCardTile.vue";
import PrintButton from "@/lib/components/PrintButton.vue";
import {qsParams, isEmpty, groupBy, generateId } from "@/lib/utilities";

interface QueryData {
  filters: { key: string; value: string;}[];
}
interface Data extends QueryData  {
  selectedGroup: string[];
  groups: any[];
  selectedType: string[];
  types: any[];
  selectedLocation: string[];
  locations: any[];
  allLabel: string;
  filteredItems: any[];
  initialSearchQuery: string;
  searchQuery: string;
  filterIndex: string;
}

export default Vue.extend({
  components: { PrintButton, EventCardTile},
  props: {
    filterVariant: { type: String, default: "light" },
    tableVariant: {type: String, default: "white"},
    maalgruppeForDropdown: {type: Array, required: true},
    locationsForDropdown: {type: Array, required: true},
    title: { type: String, required: true },
    teaser: { type: String, required: true },
    events: { type: Array, required: true },
    hasSearchInput: { type: Boolean, default: false },
    filterOptions: {type: Array, default: null},
  },
  data(): Data {
    const { query } = qsParams();
    return {
      selectedGroup: [],
      filters: [],
      filteredItems: this.events,
      initialSearchQuery: query,
      searchQuery: query || "",
      groups: [],
      selectedType: [],
      types: [],
      selectedLocation: [],
      locations: [],
      allLabel: "Alle",
      filterIndex: generateId()
    };
  },
  computed: {
    filteredEvents(): any[] {
      var selectedGroup = this.selectedGroup;
      var selectedType = this.selectedType;
      var selectedLocation = this.selectedLocation;

      return this.events.filter(function(event: any): any {
        return (
          selectedLocation.includes(event.location) &&
          selectedType.includes(event.eventType) &&
          event.targetGroups.some((group: any) => selectedGroup.includes(group))
        );
      });
    }
  },
  created() {
    var compareLocal = (a: string, b: string) => a.localeCompare(b);

    // Setting initial event filters
    this.groups = this.selectedGroup = [
      ...new Set(
        this.events
          .map((event: any) => event.targetGroups)
          .flat()
          .concat()
          .sort(compareLocal)
      )
    ];
    this.types = this.selectedType = [
      ...new Set(
        this.events
          .map((event: any) => event.eventType)
          .concat()
          .sort(compareLocal)
      )
    ];
    this.locations = this.selectedLocation = [
      ...new Set(
        this.events
          .map((event: any) => event.location)
          .concat()
          .sort(compareLocal)
      )
    ];
  },
  methods: {
    isEmpty,
    setFilter(filters: Data["filters"]){
      this.filters = filters;
      var filteredItemsData = this.events.filter((item) =>
        this.onFilter(item, filters)
      );
      this.filteredItems = filteredItemsData;
    },
    onFilter(item: any, filters: Data["filters"]): boolean {
      const filterGroup = groupBy(filters, "key");
      return Object.keys(filterGroup).every((key) => {
        const groupFilters = filterGroup[key];

        return groupFilters.some((filter: any) => {
          let content = item[filter.key];
          if (content == null) return false;

          if (Array.isArray(content)) {
            if (content.length > 0 && typeof content[0] === "object") {
              content = content.map((i) => i.name || i.value);
            }
            return content.includes(filter.value);
          } else {
            return content === filter.value;
          }
        });
      });
    },
  }
});
