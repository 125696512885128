






























import Vue from "vue";
import SkiImage from "@/lib/components/SkiImage.vue";
import dompurify from "dompurify";

export default Vue.extend({
  components: { SkiImage },
  props: {
    quote: { type: String, required: true },
    imageUrl: { type: String, default: null },
    imageAltText: { type: String, default: null },
    name: { type: String, default: null },
    title: { type: String, default: null },
    bw: { type: Boolean, default: false }
  },
  computed: {
    dataHTML() {
      // Strip all attributes from html
      const htmlString = dompurify.sanitize(this.title, {
        ALLOWED_TAGS: ["a", "strong"],
        ALLOWED_ATTR: ["href", "target"]
      });

      const html = new DOMParser().parseFromString(htmlString, "text/html");

      return html.documentElement.outerHTML;
    }
  }
});
