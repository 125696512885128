













import Vue from "vue";
import DocumentCard from "@/lib/components/DocumentCard.vue";
import { getFileTypeFromExtension } from "@/lib/utilities";

export default Vue.extend({
  components: { DocumentCard },
  props: {
    docs: { type: Array, default: () => [] }
  },
  methods: {
    getFileTypeFromExtension
  }
});
