































import Vue from "vue";
import MySkiRowHeader from "@/lib/partials/my-ski/MySkiRowHeader.vue";
import MySkiMyAgreements from "@/lib/partials/my-ski/modules/MySkiMyAgreements.vue";

interface Data {
  hidden: boolean;
  printing: boolean;
}

export default Vue.extend({
  components: { MySkiRowHeader, MySkiMyAgreements },
  props: {
    title: { type: String, required: true },
    description: { type: String, default: "" },
    linkItemUrls: { type: Array, required: true },
    fields: { type: Array, required: true },
    sortOptions: { type: Array, required: true },
    filterOptions: { type: Array, required: true },
    applicationKeys: { type: Array, default: null },
    hasSearchInput: { type: Boolean, default: false },
    tableId: { type: String, required: true },
    tableClass: { type: String, default: "" },
    scrollEnabled: { type: Boolean, default: true },
    hasTableGradient: { type: Boolean, default: false },
    csvFilename: { type: String, default: "" },
    hideThisOnEmpty: { type: Boolean, default: false },
    defaultSortBy: { type: String, default: "" },
    dateFilter: { type: String, default: ""}
  },
  data(): Data {
    return {
      hidden: false,
      printing: false,
    };
  },
  updated() {
    if (this.printing) {
      this.$nextTick(() => {
        window.print();
        this.printing = false;
      });
    }
  },
  methods: {
    hideThisBlock() {
      this.hidden = true;
    },
    printThisBlock() {
      this.printing = true;
    },
  },
});
