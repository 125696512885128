












import Vue from "vue";
import { saveAs } from "file-saver";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import MySkiRow from "@/lib/partials/my-ski/MySkiRow.vue";

export default Vue.extend({
  components: { SkiDefaultButton, MySkiRow },
  data() {
    return {
      query: qsParams(),
      failsafeTimeout: 0,
      downloadCompleted: false,
    };
  },
  async mounted() {
    const { documentId } = this.query;
    const response = await this.$api.DocumentRepository.getDocument(documentId);
    saveAs(response.data, `${response.fileName}`);
    this.downloadCompleted = true;
  },
});
