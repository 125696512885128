























































































































































import Vue from "vue";
import Accordion from "@/lib/components/Accordion.vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import SkiTable from "@/lib/components/SkiTable.vue";
import PrintButton from "@/lib/components/PrintButton.vue";

import {
  qsParams,
  isEmpty,
  getValue,
  setHighestPriorityCustomerApplication,
  generateId,
} from "@/lib/utilities";
import { isDateAfterToday } from "@/lib/dateUtilites";

export default Vue.extend({
  components: { Accordion, SkiLink, SkiTable, PrintButton },
  props: {
    fields: { type: Array, default: null },
    supplierRole: { type: String, required: true },
    consortiumRole: { type: String, required: true },
    consortiumSupplierLabel: { type: String, required: true },
    consortiumSupplierFields: { type: Array, required: true },
    supplierAgreementLabel: { type: String, required: true },
    supplierAgreementLinkLabel: { type: String, required: true },
    // supplierAgreementCustomerLabel: { type: String, required: true },
    // supplierAgreementCustomerKey: { type: String, required: true },
    supplierAgreementContactLabel: { type: String, required: true },
    supplierAgreementContactFields: { type: Array, required: true },
    customerRole: { type: String, required: true },
    customerAgreementLabel: { type: String, required: true },
    customerAgreementDescription: { type: String, required: true },
    customerAgreementFields: { type: Array, required: true },
    customerProjectGroupLabel: { type: String, required: true },
    customerProjectGroupDescription: { type: String, required: true },
    customerProjectGroupFields: { type: Array, required: true },
    linkItemUrl: { type: String, required: true },
  },
  data() {
    return {
      org: null as any,
      tableIndex1: generateId(),
      tableIndex2: generateId(),
      tableIndex3: generateId(),
    };
  },
  created() {
    this.fetchOrganization();
  },
  methods: {
    isEmpty,
    getValue,
    async fetchOrganization() {
      var org = await this.$api.organizationID.get(qsParams().id);
      if (org != null) {
        this.org = org;
        setHighestPriorityCustomerApplication(this, org.agreement);
      }
    },
    filterList(agreements: any[] | null, key: string) {
      if (!agreements) return [];

      const { status } = this.$config.organization.agreement;
      return agreements.filter(
        (a) =>
          a[status.key] === status[key] && isDateAfterToday(a.terminationDate)
      );
    },
    skipSKICustomerNumber(key: string) {
      if (this.org !== null) {
        // @ts-ignore: Object is possibly 'null'.
        return !(key === "ean" && this.org.role !== this.customerRole);
      } else {
        return true;
      }
    },
    getDistinctProjectGroups() {
      const uniqueAgreementIds = new Set();
      const resultArray = [];
      if (this.org) {
        for (const projectGroup of this.org.projectGroup) {
          if (!uniqueAgreementIds.has(projectGroup.agreementId)) {
            uniqueAgreementIds.add(projectGroup.agreementId);
            resultArray.push(projectGroup);
          }
        }
      }
      return resultArray;
    },
    hasNonEmptyAgreementList(agreement: any): boolean {
      return !isEmpty(this.filterList(agreement, "agreement"));
    },
  },
});
