















































































import Profile from "@/lib/assets/icon/profile.svg";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import { resetHashtagValue } from "@/lib/urlUtilities";
import {
  replaceHexWithNordicLettersAndWhitespace,
  scrollToAnchorItem
} from "@/lib/utilities";
import Vue from "vue";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagmySkiId: string;
}

export default Vue.extend({
  components: { SkiDefaultButton, Profile },
  props: {
    title: { type: String, required: true },
    buttonMySkiLabel: { type: String, required: true },
    buttonMySkiUrl: { type: String, required: true },
    buttonMySkiTarget: { type: String, default: null },
    noProfile: { type: String, required: true },
    linkSignUpLabel: { type: String, required: true },
    linkSignUpUrl: { type: String, required: true },
    linkSignUpTarget: { type: String, default: null },
    isLeftAligned: { type: Boolean, default: true },
    buttonVariant: { type: String, default: "primary" },
    variant: { type: String, default: "primary" },
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(
        window.location.hash
      ),
      hashtagItem: "",
      hashtagmySkiId: "#" + this.title.toLowerCase(),
    };
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(
      this.hashtagFromWindow
    );
    if (this.hashtagItem === this.hashtagmySkiId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    }
  },
  methods: {
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var mySkiId = this.hashtagmySkiId.split("#")[1];
      scrollToAnchorItem(mySkiId);
    },
    async loginMitSki(): Promise<void> {
      console.log("this method is deprecated");
    },
  },
});
