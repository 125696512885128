









































import Vue from "vue";
import Open from "@/lib/assets/icon/open.svg";
import Closed from "@/lib/assets/icon/minus.svg";

import { stripHtmlStyle, setSkiStyle, replaceHexWithNordicLettersAndWhitespace, scrollToItemTop, scrollToElement } from "@/lib/utilities";
import { getSearchParam, cleanupSearchHistory, resetHashtagValue } from "@/lib/urlUtilities";
import { AgreementDocumentsListViewDto } from "@/lib/api/APIService";


export default Vue.extend({
  components: { Open, Closed },
  props: {
    title: { type: String, default: null },
    content: { type: String, default: null },
    expanded: { type: Boolean, default: false },
    innerClass: { type: String, default: null },
    contentClass: { type: String, default: null },
    parentToggleFunction: {type: Function, default: () => {}},
    parentItemId: {type: String, default: null},
    index: {type: Number, default: null},
    hashtagAccordionId: {type: String, default: ""},
    hashtagFrom: {type: String, default: ""}
  },
  data() {
    return {
      isExpanded: false,
      hashtagFromWindow: window.location.hash,
      hashtagItem: "",
      documentIdFromQuery: "",
      item: this.$root.$data.item,
    };
  },
  created() {
    this.documentIdFromQuery = getSearchParam(document.location, "documentId");
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow);
    window.addEventListener(
      'popstate', this.handleHistoryChange
    );

    var primaryAccordion = document.getElementsByClassName("primary");
    if (primaryAccordion.length > 0) {
      primaryAccordion[0].addEventListener('transitionend', this.handleScrollWhenTransitioned);
    } else if (primaryAccordion.length === 0 && this.documentIdFromQuery === "") {
      this.handleScrollWhenTransitioned();
    }

    if (primaryAccordion.length > 0 && this.isExpanded !== this.expanded) {
      this.toggleExpand();
    }

    if (this.documentIdFromQuery !== "" && (this.title.toLocaleLowerCase().includes("aftaledokumenter") || this.title.toLocaleLowerCase().includes("vejledninger"))) {
        let elementId = this.getAccordionName();
        const documentAccordionElement = document.getElementById(elementId);
        const documentBelongsToAccordion = this.documentBelongsToAccordion();

        if (documentAccordionElement && documentBelongsToAccordion) {
          documentAccordionElement.addEventListener('transitionend', this.handleDocumentScroll);
          this.toggleExpand();
        }
      }
  },
  beforeDestroy() {
    window.removeEventListener(
      'popstate', this.handleHistoryChange
    );

    var primaryAccordion = document.getElementsByClassName("primary");
    if (primaryAccordion.length > 0) {
      primaryAccordion[0].removeEventListener('transitionend', this.handleScrollWhenTransitioned);
    }

    if (this.documentIdFromQuery !== "" && (this.title.toLocaleLowerCase().includes("aftaledokumenter") || this.title.toLocaleLowerCase().includes("vejledninger"))) {
      let elementId = this.getAccordionName();
      const documentAccordionElement = document.getElementById(elementId);
      const documentBelongsToAccordion = this.documentBelongsToAccordion();

      if (documentAccordionElement && documentBelongsToAccordion) {
        documentAccordionElement.addEventListener('transitionend', this.handleDocumentScroll);
        this.toggleExpand();
      }
    }
  },
  methods: {
    getAccordionName() {
      switch (this.title.toLowerCase()) {
        case 'vejledninger for kunder':
        case 'vejledninger for leverandører':
        case 'aftaledokumenter':
          return this.title.toLowerCase();
        default:
          return '';
      }
    },
    getDocuments() {
      return this.item["document"] || [] as AgreementDocumentsListViewDto[];
    },
    documentBelongsToAccordion() {
      let documents: AgreementDocumentsListViewDto[] = this.getDocuments();
      const documentBelongsToAccordion = documents.find(x => {
        const documentIdMatches = x.documentId === this.documentIdFromQuery;
        const accordionNameMatches = x.accordionName && x.accordionName.toLowerCase() === this.getAccordionName().toLowerCase();
        // If accordionName is missing, the document belong to "Aftaledokumenter" by default. 
        const accordionNameIsNullAndAccordionIsDefault = x.accordionName === null && this.getAccordionName() === 'aftaledokumenter';
  
        return documentIdMatches && (accordionNameMatches || accordionNameIsNullAndAccordionIsDefault
        );
      });

      return documentBelongsToAccordion;
    },
    stripHtmlStyle,
    handleHistoryChange() {
      this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(window.location.hash);
      if (this.hashtagItem === this.hashtagAccordionId) {
        if (!this.isExpanded) {
          this.toggleExpand();
        }
      }
    },
    handleScrollEvent() {
      if (this.hashtagItem === this.hashtagAccordionId) {
        this.scrollToAnchorItem(undefined);
        resetHashtagValue();
      };
    },
    handleScrollWhenTransitioned() {
      this.handleScrollEvent();
    },
    toggleExpand(event?: Event) {
      if (event) event.preventDefault();
      this.isExpanded = !this.isExpanded;
      this.$emit("toggle-expand", this.isExpanded);
      if (this.isExpanded) setTimeout(setSkiStyle, 1);
      if (this.parentToggleFunction && this.isExpanded) { 
        this.parentToggleFunction(this.parentItemId, this.index);
      };
    },
    handleDocumentScroll(){
      // Consider moving this logic (and in the method scrollToElement from utilities to a composable when code is upgraded to Vue 3), to make is generic and reusable for other components
      const documentElement = document.getElementById(`documentId-${this.documentIdFromQuery}`);

      if (documentElement) {
        scrollToElement(documentElement);

        cleanupSearchHistory(["documentId"]);
        this.documentIdFromQuery = '';
      }
    },
    scrollToAnchorItem(elementId: string| undefined) {
      // Consider moving this logic (and in the method scrollToElement from utilities to a composable when code is upgraded to Vue 3), to make is generic and reusable for other components

      // Remove hashtag from prop to get the id of the element
      const accordionId = elementId || this.hashtagAccordionId.split("#")[1];
      var element = document.getElementById(accordionId);
      if (element) {
        if (!this.isExpanded) {
          this.toggleExpand();
        }
        scrollToItemTop(element);
      }
    }
  }
});
