















































import Vue from "vue";
import Close from "@/lib/assets/icon/close.svg";

import MySkiSubscriptionModalAgreements from "@/lib/partials/my-ski/modules/MySkiStatusUpdate/MySkiSubscriptionModalAgreements.vue";
import MySkiSubscriptionModalSubscribed from "@/lib/partials/my-ski/modules/MySkiStatusUpdate/MySkiSubscriptionModalSubscribed.vue";
import {
  saveUpdateId,
  deleteUpdateId,
  loadUpdateId,
  alertException,
  deleteCurrentSubscriptions,
} from "@/lib/utilities";
import { StatusUpdateProgressStatus } from "@/lib/api/APIService";

interface Data {
  clickedSend: boolean;
}

export default Vue.extend({
  components: {
    Close,
    MySkiSubscriptionModalAgreements,
    MySkiSubscriptionModalSubscribed,
  },
  props: {
    id: { type: String, default: "mySkiModal" },
    title: { type: String, required: true },
    text: { type: String },
    submitButtonText: { type: String, required: true },
    titleChanged: { type: String, required: true },
    textChanged: { type: String, required: true },
    removeAllSubsText: { type: String, required: true },
    subscribedAgreements: { type: Array, default: null },
    filterData: { type: Object, default: null },
    filterOptions: { type: Array, default: null },
    sortOptions: { type: Array, default: null },
    skiStatuses: { type: Array, required: true },
  },
  created() {
    this.$root.$on("bv::modal::hidden", this.resetModal);
    const id = loadUpdateId();
    if (id) {
      this.pollStartUpdateStatus(id.id);
    }
  },
  data(): Data {
    return {
      clickedSend: false,
    };
  },
  methods: {
    changeStateOnClick(json: any) {
      this.clickedSend = true;
      this.pollStartUpdateStatus(json.instanceId);
      saveUpdateId(json.instanceId);
    },
    resetModal() {
      this.clickedSend = false;
    },
    async pollStartUpdateStatus(statusId: string) {
      var interval = setInterval(() => {
        this.pollUpdateStatus(statusId, interval);
      }, 2000);
    },
    async pollUpdateStatus(statusId: string, interval: any) {
      try {
        const response = await this.$api.StatusUpdateRepository.getStatus(
          statusId
        );
        if (response.status === StatusUpdateProgressStatus._1) {
          // completed
          clearInterval(interval);
          deleteUpdateId();
        }
      } catch (err) {
        clearInterval(interval);
        deleteUpdateId();
        deleteCurrentSubscriptions();
        alertException(this, err.status, "Fejl!");
      }
    },
  },
});
