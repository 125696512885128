















































import Vue from "vue";
import Close from "@/lib/assets/icon/close.svg";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";

export default Vue.extend({
  components: { Close, SkiDefaultButton },
  props: {
    callbackUrl: { type: String, required: false, default: "" },
    modalKeys: { type: Object, required: true }
  },
  computed: {
    loginUrl(): string {      
      let url = this.$root.$data.loginUrl;
      url += `?redirect=${encodeURIComponent(this.callbackUrl)}`;
      return url;
    }
  }
});
