














import Vue from "vue";
import { getDescription, stripHtmlStyle } from "@/lib/utilities";

export default Vue.extend({
  props: {
    contentArray: { type: Array, required: true },
    fields: { type: Array, default: null }
  },
  data() {
    return {
      apiGroupKeys: this.$config.agreement.descriptions.expertGroup,
      description: ""
    };
  },
  created() {
    const { expertGroup } = this.$config.agreement.descriptions;
    const description = getDescription(this, expertGroup.key);

    this.description = description;
  },
  methods: {
    stripHtmlStyle,
    itemLink(item: any): string {
      const { organizationUrl } = this.$root.$data;
      return `${organizationUrl}?id=${item[this.apiGroupKeys.orgId]}`;
    }
  }
});
